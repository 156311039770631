<template>
  <div class='w-full overflow-x-auto overflow-y-auto px-2'>
    <div v-for='field in drilldownInvestmentFiles'
      :key='`investmeent-${drilldownInvestmentId}-field-${field.id}`'
      class='mt-2 mb-6 border-b pb-2 text-xs'>
      <h3 class='font-semibold mb-2'>{{ field.custom_field_name }}</h3>
      <div class='flex flex-col justify-start items-stretch gap-y-4'>
        <div v-for='(file, index) in drilldownFieldsById(field.id)'
          :key='`file-for-field-${field.id}-${index}`'>
          <div v-if='file.file_url'
            class='bg-gray-200 px-2 py-3 text-gray-800 rounded w-full truncate'
            @click='openFileInApp(file.file_url)'>
            {{ file.display_value }}
          </div>
          <div v-else
            class='text-gray-600 uppercase'>
            no file
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'InvestmentFilesMobile',
  components: {
  },
  data () {
    return {
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.getCustomFieldsForInvestmentByType('file')
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestmentCustomFields',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
      'drilldownInvestmentFiles',
      'hasDrilldownInvestmentFiles',
    ]),
  },
  methods: {
    ...mapActions('investments', [
      'getCustomFieldsForInvestmentByType',
    ]),
    drilldownFieldsById (id) {
      return this.drilldownInvestmentCustomFields.filter(field => field.id === id)
    },
    openFileInApp (filePath) {
      /* eslint-disable */
      try { 
        const fileUrl = filePath
        const fileName = filePath.split('/').pop()
        const fileExtension = fileName.split('.').pop()
        const fileToOpen = {
            url: fileUrl,
            fileName: fileName,
            fileExtension: fileExtension,
        }
        const message = JSON.stringify(fileToOpen)
        openFile.postMessage(message)
        console.log('send fileOpen channel message: ', message)
        
        // keep the old openFile post message for backward compatibility
        openFile.postMessage(filePath)
      } catch (err) { 
        console.log('error object from postMessage for open file in app:', err)
      }
      /* eslint-enable */
    },
    hideIfEmpty (filePath) {
      return !filePath ? 'hidden' : ''
    },
  },
}
</script>
